import { useEffect, useState } from "react"
import { io } from 'socket.io-client'
import AuthService from "../../service"
import { useDispatch } from "react-redux"
import { setLoading } from "../../dashboard/component/loading/store"

export function useSocket(selectedDate) {
    const [socket, setSocket] = useState(null)
    const [anotherLoading, setAnotherLoading] = useState(false)
    const dispatch = useDispatch()
    const service = new AuthService()

    useEffect(() => {
        function initSocket() { 
            if (socket) return

            const room_socket = AuthService.getToken() + '_BI' + process.env.REACT_APP_SOCKET_ROOM
            const query = 'room=' + room_socket + '&auth=' + process.env.REACT_APP_WEBSOCKET_AUTH_TOKEN
            const webSocket = io(process.env.REACT_APP_SOCKET_URL, {
                query,
                transports: ['websocket']
            })

            console.log('Socket instanciado', webSocket)

            webSocket.on("connect", () => {
                console.log('Connect SocketIO', webSocket.id)
            })

            webSocket.on("disconnect", (msg) => {
                console.log('Disconnect SocketIO', webSocket.id)
                console.log("Disconnection message", msg)
            })

            setSocket(webSocket)
        }

        initSocket()

        return () => {
            if (socket) socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (!socket) return

        function onMessageReceive(event) {

            // console.log('onMessageReceive', event.type)

            if (event.type === 'KPI_SALES') {

                // console.log("EVENT KPI_SALES", event)

                if (event.data.status === "FINISH"){
                    // console.log("FINALIZOU A BUSCA KPI_SALES")
                    let cacheKey = event.data.cacheKey;

                    service.get('/sales/card', {cacheKey: cacheKey})
                    .then(result => {
                        dispatch({ type: 'KPI_SALES', kpiSales: result, loading: false, selectedDate });
                    }).finally(() => {
                        dispatch(setLoading('KPI_SALES', false));
                    })
                }

            }

            if (event.type === 'KPI_PRODUCT') {

                // console.log("EVENT KPI_PRODUCT", event)

                if (event.data.status === "FINISH"){
                    // console.log("FINALIZOU A BUSCA KPI_PRODUCT")
                    let cacheKey = event.data.cacheKey;

                    service.get('/product/card', {cacheKey: cacheKey})
                    .then(result => {
                        dispatch({ type: 'KPI_PRODUCT', kpiProduct: result, loading: false, selectedDate });
                    }).finally(() => {
                        dispatch(setLoading('KPI_PRODUCT', false));
                    })
                }

            }

            if (event.type === 'KPI_TODAY') {

                // console.log("EVENT KPI_TODAY", event)

                if (event.data.status === "FINISH"){
                    console.log("FINALIZOU A BUSCA KPI_TODAY")

                    let cacheKey = event.data.cacheKey;
                    service.get('/realtime/today', {cacheKey: cacheKey})
                        .then(result => {
                            dispatch({ type: 'KPI_REALTIME', kpiToday: result, selectedDate });
                        }).finally(() => {
                            dispatch(setLoading('KPI_REALTIME_TODAY', false));
                        })
                }

 
            }

            if (event.type === 'KPI_REALTIME') {
           

                // console.log("EVENT KPI_REALTIME", event)

                if (event.data.status === "FINISH"){
                    console.log("FINALIZOU A BUSCA KPI_REALTIME")
                    let cacheKey = event.data.cacheKey;
                    service.get('/realtime/realtime', {cacheKey: cacheKey})
                        .then(result => {
                            dispatch({ type: 'KPI_REALTIME', kpiRealTime: result, selectedDate });
                        }).finally(() => {
                            dispatch(setLoading('KPI_REALTIME', false));
                        })
                }
            }

            if (event.type === 'KPI_IDENTIFIER') {
           
                // console.log("EVENT KPI_IDENTIFIER", event)

                if (event.data.status === "FINISH"){
                    console.log("FINALIZOU A BUSCA KPI_IDENTIFIER")
                    let cacheKey = event.data.cacheKey;
                    service.get('/settings/identificador', {cacheKey: cacheKey})
                        .then(result => {
                            dispatch({ type: 'KPI_REALTIME', listIdentifier: result, selectedDate });
                        }).finally(() => {
                            dispatch(setLoading('KPI_IDENTIFIER', false));
                        })
                }
            }

            if (event.type === 'KPI_CHECKING') {

                // console.log("EVENT KPI_CHECKING", event)

                if (event.data.status === "FINISH"){
                    // console.log("FINALIZOU A BUSCA KPI_CHECKING")
                    let cacheKey = event.data.cacheKey;
                    service.get('/checking/list', {cacheKey: cacheKey})
                        .then(result => {
                            dispatch({ type: 'KPI_CHECKING', kpiChecking: result, selectedDate });
                        }).finally(() => {
                            dispatch(setLoading('KPI_CHECKING', false));
                        })
                }
 
            }

            if (event.type === 'KPI_FINANCIAL') {

                // console.log("EVENT KPI_FINANCIAL", event)

                if (event.data.status === "FINISH"){
                    // console.log("FINALIZOU A BUSCA KPI_FINANCIAL")
                    let cacheKey = event.data.cacheKey;

                    service.get('/financialMovement/pendingList', {cacheKey: cacheKey})
                        .then(result => {
                            dispatch({ type: 'KPI_FINANCIAL', kpiFinancialPending: result, loading: false, selectedDate });
                        }).finally(() => {
                            dispatch(setLoading('KPI_FINANCIAL', false));
                        })
                }

                
            }
 
        }

        // Recebe um update de socket de outros usuários socket
        socket.on('Update', onMessageReceive)

        // Recebe um update do socket do servidor
        socket.on('reciveSocketMessage', onMessageReceive)

        return () => {
            // Recebe um update de socket de outros usuários socket
            socket.off('Update', onMessageReceive)

            // Recebe um update do socket do servidor
            socket.off('reciveSocketMessage', onMessageReceive)
        }
    }, [socket, selectedDate])

    return [anotherLoading, setAnotherLoading]
}