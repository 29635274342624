
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, CircularProgress, Divider, FormControlLabel, Grid, LinearProgress, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import React, { useState } from "react";
import YearMonthPicker from "../yearMonthPicker";
import MultipleSelectUnidade from "../multipleSelectCheck";
import AuthService from "../../service";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from "notistack";
import { format, lastDayOfMonth, subDays, subMonths } from "date-fns";
import { startOfMonth } from 'date-fns';
import { getAllLoadings, hasAnyLoading, setLoading } from "../../dashboard/component/loading/store";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import { isMobile } from "react-device-detect";
import UnidadeChips from "../unidadeChip";
import { useSocket } from "../../utils/socket";

const LOADING_LABELS = {
    KPI_REALTIME_TODAY: 'Inf. Tempo real - Seu negócio agora',
    KPI_REALTIME: 'Inf. Tempo real - Seu negócio hoje',
    KPI_IDENTIFIER: "Inf. Tempo real - Ocupação (comandas e mesas)",
    KPI_SALES: 'Vendas',
    KPI_FINANCIAL: 'Financeiro',
    KPI_PRODUCT: 'Produtos',
    KPI_CHECKING: 'Controle',
};


export default function Filter() {
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [compareWith, setCompareWith] = useState('CURRENT_DATE') // FULL_PERIOD OR CURRENT_DATE
    // const [switchState, setSwitchState] = useState(false);  // Estado para manter o valor visual do switch
    const [unidadeSelected, setUnidadeSelected] = useState([])
    const service = new AuthService()
    const unidadesByProfile = service.getProfile()?.unidadeList
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const allLoadings = useSelector((state) => getAllLoadings(state));
    const anyLoading = useSelector((state) => hasAnyLoading(state));
    const [expanded, setExpanded] = useState(false);
    const [otherLoading, setAnotherLoading] = useSocket(selectedDate)

    const handleChangeDateTo = e => {
        setSelectedDate(new Date(e));
    }
    const handleChangeSelectTo = (values) => {
        setUnidadeSelected(values.map(unidade => JSON.parse(unidade)))
    }

    const onClickBuscar = async () => {

        let status = await service.get('/redis/status', []);
        if (status[0] === "Não conectado") {
            enqueueSnackbar('Não foi possível buscar as informações, servidor Redis está offline', {
                preventDuplicate: true,
                variant: 'warning',
            })

            return
        }

        const idsUnidade = unidadeSelected.map(unidade => unidade.idUnidade).join(',');
        if (!idsUnidade) {
            enqueueSnackbar('Nenhuma unidade selecionada', {
                preventDuplicate: true,
                variant: 'warning',
            })

            return
        }


        const lastDay = lastDayOfMonth(selectedDate);
        const startOfMonthFormatted = format(startOfMonth(subMonths(selectedDate, 1)), 'yyyy-MM-dd');
        const lastDayOfMonthFormatted = format(lastDay, 'yyyy-MM-dd');
        const today = format(selectedDate, 'yyyy-MM-dd');
        const secondLastDay = subDays(lastDay, 1)
        const secondLastDayFormatted = format(secondLastDay, 'yyyy-MM-dd');


        // Cálculo de ontem
        var yesterday = new Date(selectedDate);
        yesterday.setDate(selectedDate.getDate() - 1);
        yesterday = format(yesterday, 'yyyy-MM-dd');

        const params = {
            startDate: startOfMonthFormatted,
            endDate: lastDayOfMonthFormatted,
            today,
            yesterday,
            unidade: idsUnidade
        };


        dispatch(setLoading('KPI_SALES', true));
        dispatch({ type: 'KPI_SALES', kpiSales: [], selectedDate });

        dispatch(setLoading('KPI_PRODUCT', true));
        dispatch({ type: 'KPI_PRODUCT', kpiProduct: [], selectedDate });

        dispatch(setLoading('KPI_REALTIME_TODAY', true));
        dispatch({ type: 'KPI_REALTIME', kpiToday: [], selectedDate });

        dispatch(setLoading('KPI_REALTIME', true));
        dispatch({ type: 'KPI_REALTIME', kpiRealTime: [], selectedDate });

        dispatch(setLoading('KPI_IDENTIFIER', true));
        dispatch({ type: 'KPI_REALTIME', listIdentifier: [], selectedDate });

        dispatch(setLoading('KPI_CHECKING', true));
        dispatch({ type: 'KPI_CHECKING', kpiChecking: [], selectedDate });

        dispatch(setLoading('KPI_FINANCIAL', true));
        dispatch({ type: 'KPI_FINANCIAL', kpiFinancialPending: [], selectedDate });


        service.get('/sales/card', params)
            .then(result => {
                if (result != "LOADING") {
                    dispatch(setLoading('KPI_SALES', false));
                    dispatch({ type: 'KPI_SALES', kpiSales: result, selectedDate, compareWith });
                }
            })

        service.get('/product/card', params)
            .then(result => {
                if (result != "LOADING") {
                    dispatch(setLoading('KPI_PRODUCT', false));
                    dispatch({ type: 'KPI_PRODUCT', kpiProduct: result, selectedDate });
                }
            });

        service.get('/financialMovement/pendingList', params)
            .then(result => {
                if (result != "LOADING") {
                    dispatch(setLoading('KPI_FINANCIAL', false));
                    dispatch({ type: 'KPI_FINANCIAL', kpiFinancialPending: result, selectedDate });
                }
            });


        service.get('/checking/list', params)
            .then(result => {
                if (result != "LOADING") {
                    dispatch(setLoading('KPI_CHECKING', false));
                    dispatch({ type: 'KPI_CHECKING', kpiChecking: result, selectedDate });
                }
            });


        const todayPromise = service.get('/realtime/today', params);
        const realTimePromise = service.get('/realtime/realtime', params);
        const settingsIdentificadorPromise = service.get('/settings/identificador', params);

        // Executa as chamadas em paralelo
        Promise.all([todayPromise, realTimePromise, settingsIdentificadorPromise])
            .then(([todayResp, realTimeResp, settingsIdentificadorResp]) => {

                console.log(' Promise.all', settingsIdentificadorPromise)
                // Disparando as ações de sucesso
                dispatch({
                    type: 'KPI_REALTIME',
                    kpiToday: todayResp,
                    kpiRealTime: realTimeResp,
                    listIdentifier: settingsIdentificadorResp,
                    selectedDate,
                });
            })
            .catch(error => {
                console.error('Erro ao buscar dados de realtime ou identificador:', error);
            })
            .finally(() => {
                // Garantir que o loading seja desativado independentemente do resultado
                dispatch(setLoading('KPI_REALTIME_TODAY', false));
                dispatch(setLoading('KPI_REALTIME', false));
                dispatch(setLoading('KPI_IDENTIFIER', false));
            });

        try {
            localStorage.setItem('filter_bi', JSON.stringify(params));
        } catch (error) {
            console.error('Error saving to localStorage:', error);
        }
    }

    // const handlePeriodoChange = (event) => {
    //     const isChecked = event.target.checked;
    //     setCompareWith(isChecked ? 'FULL_PERIOD' : 'CURRENT_DATE');
    //     setSwitchState(isChecked);  // Atualiza o estado visualmente também
    // };


    return (
        <Box>
            <Grid container spacing={2} alignItems="center" direction="row">
                <Grid item md={3} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <YearMonthPicker handleChangeTo={handleChangeDateTo} />
                    </LocalizationProvider>
                </Grid>

                <Grid item md={7} xs={12}>
                    <MultipleSelectUnidade
                        fullWidth
                        unidadeList={unidadesByProfile || []}
                        handleChangeTo={handleChangeSelectTo}
                    />
                </Grid>

                <Grid item md={2} xs={12}>
                    <LoadingButton
                        variant="outlined"
                        onClick={onClickBuscar}
                        loadingIndicator="Buscando..."
                        fullWidth
                        loadingPosition="center"
                        startIcon={<SearchIcon />}
                        loading={anyLoading}
                    >
                        Buscar
                    </LoadingButton>
                </Grid>


            </Grid>

            {/* Switch para controlar o tipo de período */}
            {/* <Box mt={2}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={switchState}
                            onChange={handlePeriodoChange}
                            name="periodoCompleto"
                            color="primary"
                        />
                    }
                    label="Comparar com o período completo"
                />
            </Box> */}

            {anyLoading && (
                <Box mt={2}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Box display="flex" alignItems="center">
                                <Box mr={1}>
                                    <CircularProgress size={20} />
                                </Box>
                                Buscando dados... Esse processo pode levar alguns minutos.
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {Object.entries(allLoadings).map(([key, value]) => (
                                            <TableRow key={key}>
                                                <TableCell component="th" scope="row">
                                                    {LOADING_LABELS[key]}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {value ? (
                                                        <LinearProgress color="secondary" />
                                                    ) : (
                                                        <CheckIcon color="success" />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}

            <Box mt={2} mb={2}>
                <UnidadeChips unidades={unidadeSelected} />

            </Box>

            <Divider />
        </Box>
    );

}