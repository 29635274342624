import {
  Card,
  ProgressBar,
  Text,
  Flex,
  Metric,
  BadgeDelta,
  Title,
  Col,
  Grid,
} from "@tremor/react";
import { formatNumber } from "../../../../utils/number";
import SimpleKpiCard from "../../../component/simpleKpiCard";
import { CanceledOrderCountRealTimeKPI, CustomerCountRealTimeKPI, OrderAverageTicketRealTimeKPI, TodayOrderCountRealTimeKPI } from "../../helper";
import { useSelector } from "react-redux";

export default function CardInfoToday(props) {
  const kpiRealTime = useSelector((state) => state.kpiRealTime)
  const cardCustomerCountRealTimeKPI = CustomerCountRealTimeKPI(kpiRealTime)
  const cardCanceledOrderCountRealTimeKPI = CanceledOrderCountRealTimeKPI(kpiRealTime)
  const cardTodayOrderCountRealTimeKPI = TodayOrderCountRealTimeKPI(kpiRealTime)
  const cardOrderAverageTicketRealTimeKPI = OrderAverageTicketRealTimeKPI(kpiRealTime)

  const badgeDeltaType = (badgeDelta) => {
    if (badgeDelta < 0) {
      return 'moderateDecrease';
    } else if (badgeDelta > 0) {
      return 'moderateIncrease';
    } else {
      return 'unchanged';
    }
  }

  const decorationColor = (badgeDelta) => {
    if (badgeDelta < 0) {
      return 'red';
    } else if (badgeDelta > 0) {
      return 'green';
    } else {
      return 'yellow';
    }
  }
  const { metric, badgeDelta, list, target } = props

  return (
    <Card className="mx-auto" decorationColor={decorationColor(badgeDelta)} decoration="top">
      <Title>Seu negócio hoje</Title>
      <div className="mt-4" />
      <Flex alignItems="start">
        <Text>Valor total</Text>
        <BadgeDelta deltaType={badgeDeltaType(badgeDelta)}>{`${badgeDelta}%`}</BadgeDelta>
      </Flex>
      <Flex justifyContent="start" alignItems="baseline" className="space-x-3 truncate">
        <Metric>{`${metric}`}</Metric>
        <Text>{`ontem ${target}`}</Text>
      </Flex>

      
      {list?.map((item, index) => (
        <div key={index} className="space-y-2 mt-4">
          <Flex>
            <Text>{item.type}</Text>
            <Text>{`${item.percentage}% (${formatNumber(item.amount, { style: 'currency' })})`}</Text>
          </Flex>
          <ProgressBar value={item.percentage} />
        </div>
      ))}

      <Grid numItemsSm={1} numItemsMd={2} numItemsLg={2} className="gap-3 mt-6">
        <Col>
          <SimpleKpiCard key={3} {...cardTodayOrderCountRealTimeKPI} />
        </Col>

        <Col>
          <SimpleKpiCard key={2} {...cardCanceledOrderCountRealTimeKPI} />
        </Col>

        <Col>
          <SimpleKpiCard key={1} {...cardCustomerCountRealTimeKPI} />
        </Col>

        <Col>
          <SimpleKpiCard key={4} {...cardOrderAverageTicketRealTimeKPI} />
        </Col>
      </Grid>
    </Card >
  );
}