export const initialKpiRealTime = () => {
    return ({
        listAllSales: [],
        currentDaySales: [],
        lastDaySales: []

    })
}

function groupByDay(data) {
    const groupedData = {};

    data?.forEach((item) => {
        const day = new Date(item.dataPedido).getDate()

        if (!groupedData[day]) {
            groupedData[day] = {
                day: day,
                records: [],
            };
        }
        groupedData[day].records.push(item);
    });

    return Object.values(groupedData);
}

function groupByOrderTable(data) {
    const groupedData = {};

    data?.forEach((item) => {

        let table = '0'
        if (item.tipoPedido === 'MESA') {
            table = item.identificadorPrincipal
        } else {
            table = item.identificadorSecundario || '0'
        }

        if (!groupedData[table]) {
            groupedData[table] = {
                table: table,
                records: [],
            };
        }

        groupedData[table].records.push(item);
    });

    return Object.values(groupedData);
}

export const kpiRealTime = (currentState, action) => {
    const { kpiRealTime: currentKpiRealTime } = currentState;
    const {
        listIdentifier = [],
        kpiToday = [],
        kpiRealTime: openOrders = [],
        selectedDate
    } = action;

    const currentDate = new Date(selectedDate);

    // Inicializa o objeto `kpiRealTime`
    const kpiRealTime = {
        listAllSalesToday: currentKpiRealTime?.listAllSalesToday || [],
        currentDaySales: currentKpiRealTime?.currentDaySales || [],
        lastDaySales: currentKpiRealTime?.lastDaySales || [],
        countTable: currentKpiRealTime?.countTable || 0,
        listOrdersTable: currentKpiRealTime?.listOrdersTable || [],
        listOfOpenOrders: currentKpiRealTime?.listOfOpenOrders || [],
        selectedDate: currentDate,
        currentDate: currentDate.getMonth() + 1,
        currentMonth: currentDate.getMonth() + 1,
        currentDay: currentDate.getDate(),
        date: new Date()
    };

    // Manipula informações da lista de identificadores
    if (listIdentifier.length > 0) {
        const tableData = listIdentifier.find(e => e.tipo === "MESA");
        kpiRealTime.countTable = tableData ? parseFloat(tableData.quantidade) : 0;
    }

    // Manipula informações de pedidos de hoje
    if (kpiToday.length > 0) {
        kpiRealTime.listAllSalesToday = kpiToday;

        const groupedData = groupByDay(kpiToday);
        kpiRealTime.currentDaySales = groupedData.find(e => e.day === kpiRealTime.currentDay)?.records || [];
        kpiRealTime.lastDaySales = groupedData.find(e => e.day !== kpiRealTime.currentDay)?.records || [];
    }

    // Manipula informações de pedidos em aberto
    if (openOrders.length > 0) {
        kpiRealTime.listOfOpenOrders = openOrders;

        // Filtra e agrupa pedidos de mesas/comandas
        const tableOrders = openOrders.filter(e => e.tipoPedido === 'MESA' || e.tipoPedido === 'COMANDA');
        kpiRealTime.listOrdersTable = groupByOrderTable(tableOrders);
    }

    // Retorna o novo estado
    return {
        ...currentState,
        kpiRealTime
    };
};